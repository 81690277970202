<template>
  <!-- <div class=""> -->
  <div
    class="head-section row row-grid no-gutters my-5 mx-3 mx-sm-4 mx-lg-5 p-lg-5 align-items-center section section-hero section-shaped"
  >
    <div class="shape shape-style-1">
      <span class="span-100"></span>
      <span class="span-50"></span>
      <span class="span-150"></span>
      <span class="span-75"></span>
      <span class="span-50"></span>
      <span class="span-50"></span>
      <span class="span-100"></span>
    </div>
    <div class="content col-12 col-md-6 px-0 pr-md-0 pl-md-3 pl-lg-5 mb-4 order-md-2">
      <h1 class="h2" style="color: #009fe2">¡IMPULSA TU FRANQUICIA CON WOKI!</h1>
      <p class="h4" style="line-height: 2rem"
        >Te conectamos con <b>los mejores expertos</b> y consultores freelance y te damos
        todas las <b>herramientas</b> necesarias
        <b>para que lleves tu negocio al siguiente nivel</b></p
      >
    </div>
    <swiper
      class="col-12 col-md-6 d-flex align-items-center order-md-1"
      ref="Swiper"
      :options="carouselOptions"
    >
      <swiper-slide class="h-100" v-for="(item, index) in swiperData" :key="index">
        <v-img
          content-class="carousel-content"
          cover
          :aspect-ratio="4 / 3"
          :src="item.webpImg"
        >
          <div
            class="col-12 px-5"
            style="background-color: #009fe2ab; border-radius: 20px"
          >
            <v-row no-gutters style="color: #ffbd59"> #WOKITOOLS </v-row>
            <v-row no-gutters class="text-white">
              <h3 class="h5">{{ item.title }}</h3>
              <p>{{ item.desc }}</p>
            </v-row>
            <v-btn
              href="https://mailchi.mp/wokiconsulting/wokitools"
              target="_blank"
              ref="noopener"
              small
              color="#ffbd59"
            >
              Descargar herramientas
            </v-btn>
          </div>
        </v-img>
      </swiper-slide>

      <div class="custom-prev" slot="button-prev">
        <v-icon large color="#fff">mdi-chevron-left</v-icon>
        <!-- <v-icon large class="text-white" color="">mdi-arrow-left-thin</v-icon> -->
      </div>
      <div class="custom-next" slot="button-next">
        <v-icon large color="#fff">mdi-chevron-right</v-icon>
        <!-- <v-icon large class="text-white" color="">mdi-arrow-right-thin</v-icon> -->
      </div>
    </swiper>
  </div>
  <!-- </div> -->
</template>

<script>
  export default {
    name: 'WokiHead',
    created() {},
    data() {
      return {
        swiperData: [
          {
            id: 0,
            title: 'Análisis de mi negocio VS competidores',
            desc:
              '¿Cuál es la posición actual tu empresa frente a tus competidores? ¡Descúbrelo!',
            webpImg: './static/woki/competidores.webp',
            jpgImg: '',
            cta: '',
          },
          {
            id: 1,
            title: 'Herramienta Plan de negocio',
            desc:
              'Con esta herramienta podrás saber qué necesitas para montar tu negocio, de dónde saldrá el dinero para hacerlo y cuál es la previsión de gastos e ingresos.',
            webpImg: './static/woki/negocio.webp',
            jpgImg: '',
            cta: '',
          },
          {
            id: 2,
            title: 'Análisis de mercados exteriores',
            desc:
              'Averigua ya cuál es el mejor país para comenzar a internacionalizar tu empresa.',
            webpImg: './static/woki/exteriores.webp',
            jpgImg: '',
            cta: '',
          },
          {
            id: 3,
            title: 'Diagnóstico en Economía Circular',
            desc:
              'Si quieres saber si puedes alargar el ciclo de vida de tus productos y reducir costes con modelos de economía circular, esta herramienta es para ti.',
            webpImg: './static/woki/circular.webp',

            jpgImg: '',
            cta: 'descargar herramientas',
          },
          {
            id: 4,
            title: 'Diagnóstico en Benchmarking',
            desc:
              '¿Tienes información suficiente y acertada del entorno para implantar las mejores prácticas en tu empresa?',
            webpImg: './static/woki/benchmarking.webp',

            jpgImg: '',
            cta: '',
          },
          {
            id: 5,
            title: 'Diagnóstico en Innovación y Tecnología',
            desc:
              'Descubre cuál es el nivel de innovación y en que posición se encuentra respecto al de tus competidores.',
            webpImg: './static/woki/innovación.webp',

            jpgImg: '',
            cta: '',
          },
        ],
        carouselOptions: {
          loop: false,
          speed: 300,
          navigation: {
            prevEl: '.custom-prev',
            nextEl: '.custom-next',
          },
          pagination: false,
        },
      }
    },
    props: {},
    methods: {},
  }
</script>

<style lang="scss">
  .head-section {
    // WOKI MAIN SECTION
    .custom-prev.swiper-button-disabled,
    .custom-next.swiper-button-disabled {
      opacity: 0.5;
      cursor: auto;
      pointer-events: none;
    }

    .custom-prev,
    .swiper-container-rtl .custom-next {
      left: 10px;
      right: auto;
    }
    .custom-next,
    .swiper-container-rtl .custom-prev {
      right: 10px;
      left: auto;
    }

    .custom-prev,
    .custom-next {
      position: absolute;
      // top: 50%;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .carousel-content.v-responsive__content {
      flex: auto;
      max-width: 100%;
      height: 50%;
      // display: flex;
      align-self: center;
    }
  }
</style>
