<template>
  <v-form
    ref="formResquest"
    v-model="valid"
    lazy-validation
    style="width: 100%; margin: 0px"
    class="row"
  >
    <v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
      <v-text-field
        :rules="[rules.required]"
        label="Nombre *"
        v-model="name"
        style="padding: 0px"
      ></v-text-field>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
      <v-text-field
        :rules="[rules.required]"
        label="Apellido *"
        v-model="lastname"
        style="padding: 0px"
      ></v-text-field>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
      <v-text-field
        style="padding: 0px"
        v-mask="phoneMask"
        :rules="[rules.required, rules.maxNumber]"
        label="Teléfono"
        v-model="phone"
        type="text"
      ></v-text-field>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
      <v-text-field
        :rules="[rules.required]"
        label="Email *"
        v-model="email"
        style="padding: 0px"
      ></v-text-field>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-bottom: 20px">
      <div class="" style="display: flex">
        <multiselect
          track-by="id"
          label="name"
          style="width: 85%"
          placeholder="Seleccionar país"
          v-model="countryId"
          :options="countries"
          :multiple="false"
          :close-on-select="true"
          :clear-on-select="false"
          :preserve-search="false"
        ></multiselect>
        <v-icon slot="append" style="width: 15%" color="red">
          mdi-map-marker-multiple
        </v-icon>
      </div>
    </v-col>
    <v-col cols="12" lg="6" md="6" sm="12" style="padding: 0px 10px; margin-top: 10px">
      <v-text-field
        label="Nombre de tu empresa"
        v-model="business_name"
        style="padding: 0px"
      ></v-text-field>
    </v-col>
    <v-col cols="12" lg="6" md="12" sm="12" style="padding: 0px 10px; margin-top: 10px">
      <multiselect
        track-by="name"
        label="name"
        placeholder="Actividad de tu empresa"
        v-model="business_type"
        :options="selectBusinessType"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="false"
      ></multiselect>
    </v-col>
    <v-col cols="12" lg="6" md="12" sm="12" style="padding: 0px 10px; margin-top: 10px">
      <multiselect
        track-by="name"
        label="name"
        placeholder="Solicitud de servicios WOKI"
        v-model="service"
        :options="selectService"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="false"
        :preserve-search="false"
      ></multiselect>
    </v-col>
    <v-col cols="12" lg="12" md="12" sm="12" style="padding: 0px 10px; margin-top: 10px">
      <v-textarea
        :rules="[rules.required]"
        v-model="message"
        rows="2"
        style="padding: 0px"
      >
        <template v-slot:label>
          <div>Mensaje <small>*</small></div>
        </template>
      </v-textarea>

      <p style="font-size: 13px">
        Te informamos que XPOFranchise - Directorio de Franquicias cumple con la Ley de
        protección de datos de carácter personal, por lo que esta información es
        confidencial.
      </p>
      <p style="font-size: 13px">
        Te invitamos a conocer nuestros
        <a href="" style="text-decoration: underline"
          >Términos y Políticas de Privacidad</a
        >
      </p>
      <div class="text-center mb-3">
        <v-btn
          style="margin: auto; margin-top: 10px; color: #fff"
          :disabled="!valid"
          dark
          color="#003a56"
          @click.prevent="validate"
        >
          Enviar Mensaje
        </v-btn>
      </div>
    </v-col>
  </v-form>
</template>

<script>
  export default {
    props: {
      company_id: {
        type: Number,
        required: true,
      },
      country: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        loading: false,
        countries: [],
        cityId: 0,
        countryId: '',
        email: '',
        service: { name: 'WOKI Consulting' },
        name: '',
        lastname: '',
        date: new Date().toISOString(),
        phone: '',
        business_name: '',
        business_type: '',
        document_number: '',
        message: '',
        phoneMask: [
          '+',
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 200 || 'Debe poser menos de 200 caracteres',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
        },
        selectService: [
          { name: 'Información de Franquicia o Franquicia Master' },
          { name: 'Información acerca de Proveedores u Oferta Inmobiliaria' },
          { name: 'Unirse a Asociaciones de Franquicias' },
          { name: 'Servicios de consultoría' },
          { name: 'Contratar espacios publicitarios' },
          { name: 'Formar parte del Directorio y publicar o destacar tu negocio' },
          { name: 'Anunciar en la revista Su Franquicia' },
        ],
        selectBusinessType: [
          { name: 'Academias comerciales' },
          { name: 'Accesorios para coser, hilos, telas, etc' },
          { name: 'Accesorios y Artículos Femeninos' },
          { name: 'Aeropuertos' },
          { name: 'Agencias de Empleo' },
          { name: 'Agencias de Festejos' },
          { name: 'Agencias de Publicidad' },
          { name: 'Agencias de viaje' },
          { name: 'Agua' },
          { name: 'Aire Acondicionado' },
          { name: 'Alfombras, Tapicerias' },
          { name: 'Alquiler de Botes y Lanchas' },
          { name: 'Alquiler de equipos y Herramientas' },
          { name: 'Alquiler de trajes' },
          { name: 'Alquiler de Vehículos' },
          { name: 'Alquiler de video' },
          { name: 'Apuestas, Peñas' },
          { name: 'Articulos de Cuero' },
          { name: 'Artículos de Zapaterías' },
          { name: 'Artículos para Artes Plásticas' },
          { name: 'Asesorias Profesionales' },
          { name: 'Asoc. cooperativa' },
          { name: 'Asociación civil' },
          { name: 'Atracciones turisticas' },
          { name: 'Autolavado' },
          { name: 'Boutique Caballeros' },
          { name: 'Boutique Damas' },
          { name: 'Campos de Golf' },
          { name: 'Canchas de bowling (boliches)' },
          { name: 'Carpinteria' },
          { name: 'Casa de Empeño' },
          { name: 'Casas de Cambio' },
          { name: 'Celulares y Accesorios' },
          { name: 'Centro de Fotocopiado' },
          { name: 'Club' },
          { name: 'Combustibles (lubricantes, madera, carbo' },
          { name: 'Comida Rapida, Restaurant (sin Bar)' },
          { name: 'Condominio Residenciales (Servicios Publicos)' },
          { name: 'Construcción' },
          { name: 'Contadores y Auditores' },
          { name: 'Contratistas de Electricidad' },
          { name: 'Corredor de Seguros' },
          { name: 'Correo Aereo y Terrestre' },
          { name: 'Cortinas, Tapicerias' },
          { name: 'Detectives, proteccion, blindados' },
          { name: 'Discotecas, Tascas, Bares y similares' },
          { name: 'Discotiendas' },
          { name: 'Distribuidores y kioskos de peridódicos' },
          { name: 'Droguerias y Farmacias' },
          { name: 'Dulcerias, Bombonerias y Heladerías' },
          { name: 'Electrodomésticos' },
          { name: 'Equip. ortopedicos y quirurg.(riñones)' },
          { name: 'Equipos agrop. e ind.' },
          { name: 'Equipos de Oficina' },
          { name: 'Equipos de Salud' },
          { name: 'Equipos Electricos' },
          { name: 'Escuelas y Colegios' },
          { name: 'Escuelas, Academias, Estudios de danza' },
          { name: 'Estación de servicio isla' },
          { name: 'Estación de servicio tienda' },
          { name: 'Estacionamientos' },
          { name: 'Estudio Fotográfico' },
          { name: 'Ferreterías' },
          { name: 'Filatelia y Numismatica' },
          { name: 'Financiamiento de Primas de Seguros' },
          { name: 'Floristeria' },
          { name: 'Funerarias' },
          { name: 'Galerias de Arte' },
          { name: 'Gas' },
          { name: 'Gimnasios' },
          { name: 'Gráfica' },
          { name: 'Hipertiendas' },
          { name: 'Hospitales y Clínicas' },
          { name: 'Hoteleria' },
          { name: 'Ingenieros y Arquitectos' },
          { name: 'Inst. financieras, vta servici' },
          { name: 'Instituciones Financieras' },
          { name: 'Institutos Tecnológicos' },
          { name: 'Instrumentos musicales' },
          { name: 'Internet' },
          { name: 'Joyeria' },
          { name: 'Laboratorios Médicos, Odontológicos' },
          { name: 'Latonería y Pintura' },
          { name: 'Lavanderia y Limpieza' },
          { name: 'Librerías' },
          { name: 'Licoreria' },
          { name: 'Limpeza de Alfombras y Tapiceria' },
          { name: 'Limpieza y Lavado de Ventanas' },
          { name: 'Linea aerea internacionales' },
          { name: 'Líneas Aereas' },
          { name: 'Médicos' },
          { name: 'Mudanzas' },
          { name: 'Muebles y Accesorios del Hogar' },
          { name: 'Muebles y Artículos del Hogar' },
          { name: 'Óptcicas' },
          { name: 'Optometristas, oftalmologos' },
          { name: 'Organizaciones Religiosas' },
          { name: 'Organizaciones sin fines de lucro, Beneficencias.' },
          { name: 'Ostheopatic physicians' },
          { name: 'Pago de Impuestos' },
          { name: 'Panaderia, Pasteleria y Cafes' },
          { name: 'Papelerias y Utiles Escolares' },
          { name: 'Parques de diversiones' },
          { name: 'Peajes y Puentes' },
          { name: 'Perfumeria, Maquillaje y Cosmeticos' },
          { name: 'Periódico' },
          { name: 'Plomeria' },
          { name: 'Postage stamps' },
          { name: 'Productos Petroliferos y a fines' },
          { name: 'Productos Quimicos y a fines' },
          { name: 'Proveedores de cavas y estantes' },
          { name: 'Quiropracticos' },
          { name: 'Redes de computacion/serv.de informacion' },
          { name: 'Reparacion de articulos Electronicos y similares' },
          { name: 'Reparación de Equipos de Audio y Video' },
          { name: 'Reproducciones y copias' },
          { name: 'Resort' },
          { name: 'Restaurant de Lujo (con Bar)' },
          { name: 'Ropa de Bebes y Niños' },
          { name: 'Ropa Masculina y Femenina' },
          { name: 'Ropa para Toda la Familia' },
          { name: 'Salas de Cines Teatros y Museo' },
          {
            name: 'Salon de Belleza, Peluqueria, Manicuristas, Pedicuristas y similares',
          },
          { name: 'Salones de billar y pool' },
          { name: 'Salones de juego' },
          { name: 'Salones de Masajes' },
          { name: 'Sastres, Modistas, Arreglos y similares' },
          { name: 'Serivicios Médicos en General' },
          { name: 'Serv.de transport no clasificados' },
          { name: 'Servicicos de Acompañantes' },
          { name: 'Servicio de fumigacion' },
          { name: 'Servicios de Ambulancias' },
          { name: 'Servicios de campo (horticultura)' },
          { name: 'Servicios de Computacion' },
          { name: 'Servicios de cuidado infantil' },
          { name: 'Servicios de Gruas' },
          { name: 'Servicios de Piscinas' },
          { name: 'Servicios de Telecomunicacion Nacional e Internacional' },
          { name: 'Servicios domésticos' },
          { name: 'Servicios gubernamentales no clasificado' },
          { name: 'Servicios Legales, Abogados.' },
          { name: 'Servicios Odontologicos' },
          { name: 'Servicios Veterinarios' },
          { name: 'Servicios y accesorios Marinos' },
          { name: 'Talleres de aires acond. y eq. de refrig' },
          { name: 'Talleres Mecánicos' },
          { name: 'Taxis' },
          { name: 'Teléfono' },
          { name: 'Tienda de Articulos Religiosos' },
          { name: 'Tienda de Ropa Deportiva' },
          { name: 'Tiendas de Antiguedades' },
          { name: 'Tiendas de Artículos deportívos y similares' },
          { name: 'Tiendas de Computadoras Software' },
          { name: 'Tiendas de delicateses' },
          { name: 'Tiendas de Descuentos' },
          { name: 'Tiendas de mascotas' },
          { name: 'Tiendas de Pinturas, Barniz, Papel Tapiz y similares' },
          { name: 'Tiendas de Productos lácteos' },
          { name: 'Tiendas de radios, televisión, eq./sonid' },
          { name: 'Tiendas de regalos, tarjeterias' },
          { name: 'Tiendas de Variedades' },
          { name: 'Tintorerias' },
          { name: 'Toldos y persianas' },
          { name: 'Transporte de pasajeros (Terrestre y Marítimo)' },
          { name: 'Transporte, paradas de camiones' },
          { name: 'TV por cable y similares' },
          { name: 'U.k petrol stations, electronic hot file' },
          { name: 'U.k. supermarkets, electronic hot file' },
          { name: 'Uniformes' },
          { name: 'Universidades' },
          { name: 'Venta de bicicletas' },
          { name: 'Ventas de lanchas' },
          { name: 'Ventas de motos' },
          { name: 'Ventas de respuestos para vehículos,camiones,motos y similares' },
          { name: 'Ventas de vehículos y camiones' },
          { name: 'Viveros, Equipos de jardineria y similares' },
          { name: 'Zapaterías' },
          { name: 'Zapateros' },
        ],
        valid: true,
      }
    },
    watch: {
      country(newValue, oldValue) {
        this.countryId = this.countries
          .filter((country) => country.id == newValue)
          .shift()
      },
    },
    created() {
      this.getCountries()
    },
    mounted() {},
    methods: {
      hideMenu(ref) {
        this.$refs[ref].blur()
      },
      async getCountries() {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'countries?limit=100')
          .then((response) => {
            this.countries = response.data
            this.countryId = this.countries
              .filter((country) => country.id == this.country.id)
              .shift()
            this.loading = false
          })
          .catch((error) => {})
          .finally(() => {})
      },

      validate() {
        if (this.$refs.formResquest.validate()) {
          this.saveItem()
        }
      },

      async saveItem() {
        this.loading = true
        let dataPost = {}
        if (this.company_id == 0) {
          dataPost = {
            name: this.name,
            lastname: this.lastname,
            country_id: this.countryId.id,
            document_number: 'N/A',
            business_name: this.business_name ? this.business_name : 'N/A',
            business_type: this.business_type ? this.business_type.name : 'N/A',
            message: this.message,
            phone: this.phone,
            email: this.email,
            service: this.service && this.service.name ? this.service.name : 'N/A',
            status: 'pending',
            checker_id: 0,
            check_time: this.date,
          }
        } else {
          dataPost = {
            name: this.name,
            lastname: this.lastname,
            country_id: this.countryId.id,
            company_id: this.company_id,
            document_number: this.document_number,
            business_name: this.business_name ? this.business_name : 'N/A',
            business_type: this.business_type ? this.business_type.name : 'N/A',
            message: this.message,
            phone: this.phone,
            email: this.email,
            service: this.service && this.service.name ? this.service.name : 'N/A',
            status: 'pending',
            checker_id: 0,
            check_time: this.date,
          }
        }
        axios
          .post(process.env.VUE_APP_API_DIRECTORY + 'form-requests', dataPost)
          .then((response) => {
            this.$swal({
              title: `¡Hola!`,
              html: `Hemos recibido tu solicitud.<br>Te contactaremos en las próximas horas`,
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Aceptar',
            })
            this.$emit('sended')
            this.loading = false
            // this.$refs.formResquest.reset()
            this.name = ''
            this.lastname = ''
            this.phone = ''
            this.email = ''
            this.countryId = null
            this.business_name = ''
            this.business_type = ''
            this.document_number = ''
            this.message = ''
            this.service.name = ''
          })
      },
    },
  }
</script>

<style scoped>
  .col-centered {
    float: none;
    margin: 0 auto;
  }
  p {
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 1.1;
  }

  .input-example {
    width: 80%;
    outline: -webkit-focus-ring-color auto 0px;
  }
  /*.flag-example {
		width: 20%;
	}*/
  .wrraper-example {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    border-style: solid;
    border-width: 0 0 thin 0;
    bottom: -1px;
    content: '';
    left: 0;
    position: initial;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    width: 100%;
  }
</style>
